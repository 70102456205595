export class Result {
    Id: number;
    filePath: string;
    carbo: number;
    answer: number;

    constructor(id: number, filePath: string, carbo: number, answer: number) {
        this.Id = id;
        this.filePath = filePath;
        this.carbo = carbo;
        this.answer = answer;
    }
}