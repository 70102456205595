import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { Image } from '../../models/image';
import { ImageService } from "src/app/shared/services/image.service";
import * as firebase from "firebase";
import { Result } from "src/app/models/result";

@Component({
  selector: 'app-trial',
  templateUrl: './trial.component.html',
  styleUrls: ['./trial.component.scss']
})
export class TrialComponent implements OnInit {
  private images: Observable<Image[]>;
  private input: string;
  private inputList: Result[];
  private image: Image;
  private indexs: number[];

  private subscribe: Subscription;

  constructor(private imageService: ImageService) {
    this.inputList = new Array();
    this.indexs = this.imageService.getIndex();
  }

  ngOnInit() {
    this.getImageData();
    this.input = "";
  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }

  /*
   * 食べ物取得
   */
  getImageData() {
    this.images = this.imageService.getItem(this.indexs[this.inputList.length]);
    this.subscribe = this.images.subscribe(
      images => {
        this.image = images[0];
      });
  }

  // 数字ボタンクリックイベント  
  onNumberClick(number: string) {
    if (this.input === undefined) {
      if (number === ".5") {
        // 未入力で.5をクリックしたら0.5とする
        this.input = "0.5";
        return;
      }
      this.input = "";
    }
    else {
      if (this.input.indexOf(".5") > -1) {
        // .5が入力済みをクリックははじく
        return;
      }
    }
    this.input = this.input + number;
  }

  /*
  * リセットボタンクリックイベント
  */
  onResetClick() {
    this.input = undefined;
  }

  /*
  * 次へボタンクリックイベント
  */
  onNextClick() {
    this.inputList.push(new Result(this.image.Id, this.image.filePath.replace('.jpg', ''), this.image.carbo, parseFloat(this.input === "" ? '0' : this.input)));
    this.input = "";

    if (this.inputList.length > 4) {
      return;
    }

    this.getImageData();
  }

  /*
  * 前へボタンクリックイベント
  */
  onPreviousClick() {
    if (0 > this.inputList.length) {
      return;
    }

    this.inputList.pop();
    this.input = "";

    this.getImageData();
  }

  onReload() {
    location.reload();
  }
}