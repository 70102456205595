import { Injectable } from '@angular/core';
import { Image } from '../../models/image';
import { Observable, Subject } from 'rxjs/index';
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/firestore";
import * as firebase from "firebase";

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  private imageCollection: AngularFirestoreCollection<Image>;
  private db: AngularFirestore;

  constructor(db: AngularFirestore) {
    this.db = db;
  }

  /*
  * ランダムで5問取得するための5つのID値を取得する
  */
  getIndex(): number[] {
    var randoms = [];
    var min = 0;
    var max = 99;

    for (var i = 0; i < 5; i++) {
      while (true) {
        var tmp = Math.floor(Math.random() * (max - min + 1) + min);
        if (!randoms.includes(tmp)) {
          randoms.push(tmp);
          break;
        }
      }
    }

    return randoms;
  }

  /*
  * index指定でアイテムを取得する
  */
  getItem(index: number): Observable<Image[]> {
    this.imageCollection = this.db.collection<Image>('images', ref => {
      return ref.where('Id', '==', index);
    });

    return this.imageCollection.valueChanges();
  }
}
