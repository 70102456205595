import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  private notices: Observable<any[]>;
  private db: AngularFirestore;

  constructor(db: AngularFirestore) {
    this.db = db;

    this.notices = this.db.collection('notices').valueChanges();
  }

  ngOnInit() {
  }
}
