import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {
  private downloadInfos: Observable<any[]>;
  private db: AngularFirestore;

  constructor(db: AngularFirestore) {
    this.db = db;
    this.downloadInfos = this.db.collection('file').valueChanges();
  }

  ngOnInit() {
  }

}
